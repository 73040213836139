import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { UserAgentApplication } from "msal";
import { msalAccessAuthToken } from "../msal/authConfig";
import { handleSilentLogin, loginRequest, msalConfig } from "../msal/loginrequest";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import IconMenuItem from '../../component/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Drawer from "@material-ui/core/Drawer";
import HomeIcon from '@material-ui/icons/Home';
import logo from '../../assets/icons/logo.png';
import objectsNotifications from '../../assets/icons/objectsNotifications.svg';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Bookmark from "../../views/Bookmark/index";
import Feedback from "../../views/Feedback/index";
import UpdateMessage from '../../views/updateException/index';
import Modal from "../../component/modal";
import cloneDeep from 'lodash/cloneDeep';
import Badge from '@material-ui/core/Badge';
import NotificationCenter from '../../views/Notification/notificationCenter';
import utils from '../../utils';
import profileAvatar from '../../assets/icons/profileAvatar.svg';
import home from '../../assets/icons/home.svg';
import exportShare from '../../assets/icons/exportShare.svg';
import verticaLine from '../../assets/icons/line.svg';
import {
  updateBusinessUnitRegion,
  updateOktaAuth,
  trackUserEvent,
  updateRegionFilters,
  resetPlaybooksRegionFilters,
  getRegions,
  updateBusinessUnitSubRegion,
  updatesidebarMenuList,
  updateRegionSpecificConfigs,
  updateFirstTimeAppLoadStatus,
  updateMsalToken,
  updateNotificationRegion,
  fetchNotification,
  updateReportContent,
  updatePowerBIReport,
  updateCustomizedViewBuilderJSON,
  updateBookmarkViews,
  updatePresistReport,
  updateActiveSubMenuList,
  updateActivePage,
  getErrorMessages,
  updateUserLoginDetails,
  fetehUserProfile,
  addNewUserDetails
} from '../../redux/actions/index';
import Share from '../../views/share/share';
import Export from '../../views/Export/index';
// const msalInstance = new UserAgentApplication(msalConfig);


function Header(props) {
  const classes = useStyles();


  const dispatch = useDispatch();
  const history = useHistory();
  const pathName = window.location.pathname;
  const { authState, oktaAuth } = useOktaAuth();

  const regionList = useSelector((state) => state.regions);
  const ShareLinkData = useSelector((state) => state.fetchShareLinkDetails);
  const notificationRegion = useSelector((state) => state.notificationRegion);
  const errorMessage = useSelector((state) => state.getErrorMessages);
  const notificationData = useSelector((state) => state.notification);
  const apiFailure = useSelector((state) => state.apiFailure);
  const activeReport = useSelector((state) => state.activePBIReport);
  const { activeRoutes, activeRegionSpecificConfigs, firstTimeAppLoad, disabledFeatures } = useSelector((state) => state.activeDynamicData);

  const [user, setUser] = useState('');
  const [admin, setAdmin] = useState('admin');
  const [regions, setRegions] = useState([]);
  const [selRegion, setSelRegion] = useState('loading...');
  const [activeRegion, setActiveRegion] = useState('loading...');
  const [activeSubRegion, setActiveSubRegion] = useState('loading...');
  const [closeAnchorEl, setCloseAnchorEl] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(false);
  const [notificationCenter, setNoficationCenter] = useState({ right: false });
  const [notifications, setNofification] = useState(0);


  let userData = sessionStorage.getItem('oktaRes');
  let userInfo = !!userData ? JSON.parse(userData) : null;

  // console.log('updatedActiveReportPages', activePage);
  /**
     * useEffect hook for updating application load status.
     *
     * @example
     * @state
     * update First Time Application Load Status
     *
  */
  useEffect(() => {
    if (firstTimeAppLoad && pathName !== "/" && pathName !== "/msalcallback") {
      dispatch(updateFirstTimeAppLoadStatus(false));
    }
  }, [dispatch, firstTimeAppLoad, pathName]);

  /**
     * useEffect hook for checking status of okta tokens.
     *
     * @example
     * @state
     * okta access Token property
     *
  */
  useEffect(() => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (oktaTokenStorage) {
      if (!oktaTokenStorage.hasOwnProperty("accessToken")) {
        sessionStorage.clear();
        localStorage.clear();
        history.push({
          pathname: '/',
          state: {}
        });
      }
    }
  }, [history]);

  /**
     * useEffect hook for updating status of msal Access tokens.
     *
     * @example
     * @state
     * MSAL Access Token property
     *
  */
  useEffect(() => {
    let sessionData = sessionStorage.getItem("msalAccessToken");
    if (history.location.state && history.location.state['type'] === "msalcallback") {
      if (sessionData !== history.location.state['msalAccessToken']) {
        dispatch(updateMsalToken(history.location.state['msalAccessToken']))
      }
    }
  }, [dispatch, history]);

  /**
    * useCallback hook for msalcallback.
    *
    * @example
    * @state
    * MSAL Access Token property
    *
 */
  const msalCallback = useCallback(() => {
    let sessionData = sessionStorage.getItem("presistReport") ? sessionStorage.getItem("presistReport") : { type: "playbooks" }
    if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
      if (msalAccessAuthToken) {
        dispatch(updateMsalToken(msalAccessAuthToken))
      }
    }
    else if (!pathName.includes("share-playbooks-report") && sessionData['type'] !== 'share') {
      const msalInstance = new UserAgentApplication(msalConfig);
      if (msalInstance.getAccount()) {
        handleSilentLogin(msalInstance, loginRequest);
      }
      else {
        history.push({
          pathname: "/msalcallback",
          state: {},
        });
      }
    }
  }, [dispatch, history, pathName]);

  /**
    * useEffect hook for updating user command authenticated access.
    *
    * @example
    * @state
    * authState = okta authenticaion access
    * isAuthenticated = user is Authenticated or not
    * oktaAuth.getUser() = okta prototype funcation to fecth user details
    * regionContributor = key value pair for user contributor or read access
    * sessionId = user session Id
    * updateOktaAuth = updating authentication redux state
    * trackUserEvent = tracking user event of login
    * msalInstance = for acquring the access tokens
 */
  useEffect(() => {
    let regionContributor = '';
    let oktavalue = false;
    let loginParams = {};
    let envGroup = [];
    if (!!authState && !!authState.isAuthenticated) {
      oktaAuth.getUser().then((res) => {
        setUser(res);
        const local_Group = [
          "Azure_KC_GlobalDataLake_RGMPlaybooks_Global_NonProd_Contributor",
          'Azure_KC_GlobalDataLake_RGMPlaybooks_Global_NonProd_Read',
          'Azure_KC_GlobalDataLake_RGMPlaybooks_NA_NonRGM_NonProd_Read',
          "Azure_KC_GlobalDataLake_RGMPlaybooks_Global_Prod_Contributor",
          "Azure_KC_GlobalDataLake_RGMPlaybooks_APAC_NonProd_Contributor",
          "Azure_KC_GlobalDataLake_RGMPlaybooks_APAC_Prod_Contributor"
        ];
        const groups = process.env.REACT_APP_ENVIRONMENT === "LOCAL" ? local_Group : res.groups;
        if (Array.isArray(groups) && groups.length > 0) {
          envGroup = groups.filter((el) => el.includes(process.env.REACT_APP_OKTA_ENV));
          regionContributor = envGroup[0].split('_');
        }
        if (Array.isArray(regions) && regions.length > 0) {
          setAdmin(regionContributor[6]);
          if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
            res.groups = envGroup;
          }
          res.sessionId = `${res.sub}_${Date.now()}_${Math.floor(1000000000 + Math.random() * 9000000000)}`;
          sessionStorage.setItem('oktaRes', JSON.stringify(res));
          const params = {
            auth: true,
            okta: res,
            regions: regions,
            adminType: regionContributor[6]
          };
          dispatch(updateOktaAuth(params));
          // user Login tracking
          if (!sessionStorage.getItem('trackLoginInfo')) {
            dispatch(fetehUserProfile({ email: res.email })).then((userRes) => {
              let userDetails = userRes.data.data;
              let keyUser = Array.isArray(userDetails) && userDetails.length > 0 && userDetails[0]["Key User "];
              sessionStorage.setItem('keyUser', keyUser);
              sessionStorage.setItem('trackLoginInfo', false);
              let userParams = {
                "userName": res.name,
                "region": regions[0].region,
                "userEmail": res.email,
                "actionType": "Login",
                "pageName": "Home",
                "userGroup": Array.isArray(groups) && groups[0],
                "sessionId": res.sessionId,
                "keyUser": keyUser
              }
              loginParams['KCId'] = res.sAMAccountName;
              loginParams['Email'] = res.email;
              loginParams['adGroups'] = res.groups;
              loginParams['firstName'] = res.given_name;
              loginParams['lastName'] = res.family_name;
              loginParams['region'] = regions[0].region;
              let params = {
                userInfo: loginParams
              }
              dispatch(updateUserLoginDetails(loginParams)).then((res) => {
                 if (!res) {
                  dispatch(addNewUserDetails(params));
                }
              });
              dispatch(trackUserEvent({ userInfo: userParams }));
            })
          }
          oktavalue = true
        }
        if (oktavalue) {
          msalCallback();
        }
      })
        .catch((err) => {
          setUser(err);
        });
    }
  }, [authState, dispatch, oktaAuth, regions, msalCallback]);

  /**
    * useEffect hook to dispatch action for API call.
    *
    * @example
    * @state
    * API call to fetch Region list
    * API call to fetch error messages
    *
 */
  useEffect(() => {
    dispatch(getErrorMessages());
    dispatch(getRegions());
  }, [dispatch]);

  /**
    * useEffect hook for updating error messages.
    *
    * @example
    * @state
    * errorMessage =  command center error message list
 */
  useEffect(() => {
    const noData = errorMessage?.data?.find(item => item.errorType === "Unresponsive Server");
    errorMessage?.data && localStorage.setItem('NodeDown', JSON.stringify(noData));
  }, [errorMessage]);

  /**
    * useEffect hook to fetching user notifications.
    *
    * @example
    * @state
    * API call to fetch notifications list
    * Updating total number of notifications
  */
  useEffect(() => {
    let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
    let { notification } = notificationData;
    if (sessionData && Array.isArray(notification) && notification.length === 0) {
      let params = { email: sessionData.email }
      dispatch(fetchNotification(params)).then((res) => {
        let notification = res.data.data;
        setNofification(notification.length);
      })
        .catch((err) => { console.log('@notificationData', err); });
    }
  }, [dispatch]);

  useEffect(() => {
    let { notification } = notificationData;
    if (Array.isArray(notification) && notification.length === 0) {
      setNofification(0);
    }
  }, [notificationData]);


  /**
    * useCallback hook for updating region specific custom data.
    *
    * @example
    * @state
    * region = Active Region
    * userRegions = Screen basis regions list
    * selectedRegionData = Active Region data like sub-region
    * finalBusinessUnit = Active Region business unit data
    * finalRoutes = Active Region Final Routes
    * showFeedback = to enable feedback feature
    * msalTokenEnabled = to embed PBI report with Msal Token Enabled
    * homePageRedirectKey = to redirect to home page feature
    * showDashboardPage = to enable  Admin Dashboard
    * showUpdateMessage = to enable update error message
 */
  const updateRegionSpecificCustomData = useCallback((region, userRegions) => {
    if (Array.isArray(userRegions) && userRegions.length > 0) {
      let activeParentModule;
      let childModule;
      let storedActivePage = JSON.parse(sessionStorage.getItem('ActivePage'));
      let index = userRegions.findIndex((item) => item.region === region);
      console.log('updateActivePageRegion', userRegions, region, storedActivePage);
      if (index > -1) {
        let selectedRegionData = userRegions[index];
        let disabledScreen = selectedRegionData['disabledScreen'];
        let parentModule = selectedRegionData['businessUnits'];
        let buType = selectedRegionData['childModule'];
        let routes = selectedRegionData['routes'];
        if (Array.isArray(parentModule) && parentModule.length > 0) {
          parentModule.sort((a, b) => { return a.parentOrder - b.parentOrder });
        }
        if (storedActivePage && region !== 'Global') {
          let parentModuleIndex = parentModule.findIndex((el) => el.parentId == storedActivePage.parentId);
          activeParentModule = parentModuleIndex > -1 ? parentModule[parentModuleIndex] : parentModule[0];
          let isValid = Array.isArray(activeParentModule.childModule) && activeParentModule.childModule.length > 0;
          let childModuleIndex = isValid && activeParentModule.childModule.findIndex((el) => el.childId === storedActivePage.childId);
          childModule = isValid && childModuleIndex > -1 && activeParentModule.childModule[childModuleIndex];
          console.log('updatestoredActivePageRegion', storedActivePage);
          dispatch(updateActivePage(childModule))
        }
        else {
          activeParentModule = parentModule[0];
          let isValid = Array.isArray(activeParentModule.childModule) && activeParentModule.childModule.length > 0;
          childModule = isValid && activeParentModule.childModule[0];
          console.log('updatestoredActivePageRegion', childModule);
          dispatch(updateActivePage(childModule))
        }
        if (parentModule) {
          console.log('updateRegionSpecificConfigsRegion', region);
          dispatch(updateRegionSpecificConfigs(
            parentModule,
            routes,
            disabledScreen,
            {
              showFeedback: selectedRegionData ? selectedRegionData['showFeedback'] : false,
              msalTokenEnabled: selectedRegionData ? selectedRegionData['msalTokenEnabled'] : false,
              homePageRedirectKey: selectedRegionData ? selectedRegionData['homePageRedirectKey'] : null,
              showDashboardPage: selectedRegionData ? selectedRegionData['showDashboardPage'] : false,
              showUpdateMessage: selectedRegionData ? selectedRegionData['showUpdateMessage'] : false,
              parentPath: parentModule.key,
              buType: buType
            },
            region,
          ))
          return { finalBusinessUnit: parentModule, finalRoutes: selectedRegionData['routes'], disabledScreen: selectedRegionData['disabledScreen'] };
        }
      }
    }
  }, [dispatch]);

  /**
   * useCallback hook for updating region & sub-region & region's specific custom data.
   *
   * @example
   * @state
   * defaultRegion = Active Region
   * defaultSubRegion = Active Sub Region
   * presistRegion = Presisted Region when screen refreshes
   * defaultSubRegion = Presisted Sub Region when screen refreshes
   * ShareLinkData = Share link data
   * NotificationRegion = Notification Region for Release Notes and Newsletter
   * globalEnabled = Command Center Screen in which global region is present
   * activeScreen = Active Command Center Screen
   * updateBusinessUnitRegion =  Update Active BusinessUnit Region
   * updateBusinessUnitSubRegion =  Update Active BusinessUnit Sub Region
*/
  const updateRegionsList = useCallback((userRegions, globalEnabled) => {
    let defaultRegion = null;
    let defaultSubRegion = null;
    const presistRegion = sessionStorage.getItem('region');
    const presistSubRegion = sessionStorage.getItem('subRegion');
    if (Array.isArray(userRegions) && userRegions.length > 0) {
      if (ShareLinkData && Array.isArray(ShareLinkData.data) && ShareLinkData.data.length > 0) {
        defaultRegion = ShareLinkData.data[0].region;
        defaultSubRegion = ShareLinkData.data[0].subRegion;
      }
      else if (notificationRegion.region) {
        defaultRegion = notificationRegion.region;
        defaultSubRegion = null;
      }
      else if ((presistRegion && presistRegion !== "null") || (presistSubRegion && presistSubRegion !== "null")) {
        let activeScreen = globalEnabled.find((el) => el.screen === pathName);
        console.log('updateActivePagedefaultRegion', activeScreen);
        if (activeScreen) {
          const pathName = window.location.href;
          let urlParams;
          if (pathName.length > 0 && pathName.includes('?') && pathName !== '/') {
            urlParams = pathName.split('?')[1];
            let description = urlParams && urlParams.split('&&')[4].split('=')[1].replace(/%20/g, " ");
            let region = urlParams && urlParams.split('&&')[6].split('=')[1].replace(/%20/g, " ");
            if (description.startsWith('Global')) {
              defaultRegion = region;
              defaultSubRegion = null;
              console.log('updateActivePagedefaultRegion', 111, description, region, activeScreen, defaultRegion, urlParams.split('&&'));
            }
            else {
              defaultRegion = presistRegion;
              defaultSubRegion = presistSubRegion;
              console.log('updateActivePagedefaultRegion', 222, activeScreen, defaultRegion);
            }
            console.log('updateActivePagedefaultRegion', 222, activeScreen, defaultRegion);
          }
          else {
            defaultRegion = presistRegion;
            defaultSubRegion = presistSubRegion;
          }
        }
        else if (!activeScreen) {
          defaultRegion = presistRegion;
          defaultSubRegion = presistSubRegion;
        }
        else {
          defaultRegion = userRegions[0].region;
          let subRegion = userRegions[0].subRegion;
          if (Array.isArray(subRegion) && subRegion.length > 0) {
            defaultSubRegion = userRegions[0].subRegion[0];
          }
          console.log('updateActivePagedefaultRegion', 333, presistRegion, activeScreen, defaultRegion);
        }
      }
      else {
        defaultRegion = userRegions[0].region;
        let subRegion = userRegions[0].subRegion;
        if (Array.isArray(subRegion) && subRegion.length > 0) {
          defaultSubRegion = userRegions[0].subRegion[0];
        }
        console.log('updateActivePagedefaultRegion', 444, userRegions, defaultRegion, pathName);
      }
      const index = userRegions.findIndex(item => item.region === defaultRegion);
      console.log('updateActivePagedefaultRegion', 555, userRegions, defaultRegion, pathName);
      if (index > -1) {
        let selectedRegion = userRegions[index];
        let SelectedSubRegion = userRegions[index].subRegion;
        if (!defaultSubRegion && Array.isArray(SelectedSubRegion) && SelectedSubRegion.length > 0) {
          defaultSubRegion = SelectedSubRegion[0];
        }
        else if (!defaultSubRegion && Array.isArray(SelectedSubRegion) && SelectedSubRegion.length === 0) {
          defaultSubRegion = null;
        }
        else if (defaultSubRegion === 'null') {
          defaultSubRegion = null;
        }
        let regionParams = { region: defaultRegion, subRegionList: SelectedSubRegion, subRegion: defaultSubRegion, regionList: userRegions };
        let subRegionParams = { subRegion: defaultSubRegion, subRegionList: SelectedSubRegion };
        setActiveSubRegion(defaultSubRegion);
        setActiveRegion(defaultRegion);
        setSelRegion(selectedRegion);
        sessionStorage.setItem('region', defaultRegion);
        sessionStorage.setItem('subRegion', defaultSubRegion);
        dispatch(updateBusinessUnitRegion(regionParams));
        dispatch(updateBusinessUnitSubRegion(subRegionParams));
        updateRegionSpecificCustomData(defaultRegion, userRegions);
      }
    }
  }, [ShareLinkData, dispatch, notificationRegion.region, pathName, updateRegionSpecificCustomData]);

  /**
      * useEffect hook for updating region list specific to the screens.
      *
      * @example
      * @state
      * regionlist = Region list
      * data1 = Region list of the Screen in which global region is not present
      * data2 = Region list of the Screen in which global region is present
      * globalEnabled = Command Center Screen in which global region is present
      * updateRegionsList = Updating Region list Acc to the Active Screen
  */
  useEffect(() => {
    const regionlist = cloneDeep(regionList);
    if (regionlist.hasOwnProperty('data')) {
      let userRegions = [];
      const { data1, data2, globalEnabled } = regionlist['data'];
      if (Array.isArray(globalEnabled) && globalEnabled.length > 0) {
        let index = globalEnabled.findIndex(el => el.screen === pathName);
        if (index > -1) {
          if (Array.isArray(data2) && data2.length > 0) {
            let copy = cloneDeep(data2);
            // if (globalEnabled[index].defaultRegion) {
            //   let globalRegion = copy.find(el => el.region === "Global");
            //   if (globalRegion) {
            //     copy.splice(-1);
            //     copy.unshift(globalRegion);
            //   }
            // };
            setRegions(copy);
            userRegions = copy;
          }
        }
        else if (index === -1) {
          let copy = cloneDeep(data1);
          setRegions(copy);
          userRegions = copy;
        }
      };
      updateRegionsList(userRegions, globalEnabled);
    }
  }, [pathName, regionList, updateRegionsList]);

  /**
      * function for updating region specific custom data.
      *
      * @example
      * @state
      * activeRegion = Active Bookmark Region
      * activeSubRegion = Active Bookmark Sub Region
  */
  const updateBookmarkRegion = (val) => {
    let activeRegion = val.region;
    let activeSubRegion = val.subRegion;
    setActiveRegion(activeRegion);
    setActiveSubRegion(activeSubRegion);
    const index = regions.findIndex(item => item.region === activeRegion);
    let selRegion = regions && regions[index];
    let params = {
      region: activeRegion,
      subRegion: selRegion,
      regionList: regions
    }
    let subRegionParams = {
      subRegion: activeSubRegion,
      subRegionList: activeRegion === "Global" ? [] : regions && regions[index].subRegion
    }
    sessionStorage.setItem('region', val.region);
    sessionStorage.setItem('subRegion', val.subRegion);
    dispatch(updateBusinessUnitRegion(params));
    dispatch(updateBusinessUnitSubRegion(subRegionParams));
    setSelRegion(selRegion);
    updateRegionSpecificCustomData(activeRegion, regions);
  };


  /**
      * function for updating region selected by the user.
      *
      * @example
      * @state
      * value = Active Selected Region
      * subRegion = Active Selected Sub Region
      * resetPlaybooksRegionFilters = Reset Playbook Gloal filter on the basis of selected region
      * updateRegionFilters = Reset Regions filter on the basis of selected region
      * updatesidebarMenuList = Update Sidebar Menu List on the basis of region and active screen
      * updateNotificationRegion = update Notification Region
      * nextPathData = nextPathData is basically updating the page specific data
  */
  const handleRegionChange = (event) => {
    dispatch(updateBusinessUnitSubRegion({}));
    dispatch(resetPlaybooksRegionFilters({}));
    dispatch(updateRegionFilters({}));
    dispatch(updateReportContent());
    dispatch(updateNotificationRegion({}));
    dispatch(updatePowerBIReport({}));
    dispatch(updateCustomizedViewBuilderJSON({}));
    dispatch(updateBookmarkViews({}));
    dispatch(updatePresistReport({ presist: true }));
    dispatch(updateActiveSubMenuList({}));
    dispatch({ type: "UPDATE_SIDEBAR_MENU_LIST" });
    dispatch({ type: "GET_SIDEBAR_MENU_LIST_REQUEST" });
    dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
    dispatch({ type: "GET_ALL_PAGE_CONTENT_REQUEST" });
    dispatch({ type: "DYNAMIC_MODULE_CONTENT_REQUEST" });
    sessionStorage.removeItem('ActivePage');
    sessionStorage.removeItem('presistReport');
    let value = event.target.value;
    setActiveRegion(value);
    sessionStorage.removeItem('ActivePage');
    sessionStorage.setItem('region', value);
    let nextPathData = updateRegionSpecificCustomData(value, regions);
    const index = regions.findIndex(item => item.region === value);
    let subRegion = regions && regions[index];
    setSelRegion(subRegion);
    const params = {
      region: value,
      subRegion: subRegion,
      regionList: regions
    };
    dispatch(updateBusinessUnitRegion(params));
    if (Array.isArray(subRegion.subRegion) && subRegion.subRegion.length > 0) {
      setActiveSubRegion(subRegion.subRegion[0]);
      const params = {
        subRegion: subRegion.subRegion[0],
        subRegionList: subRegion && subRegion.subRegion
      }
      sessionStorage.setItem('subRegion', subRegion.subRegion[0]);
      dispatch(updateBusinessUnitSubRegion(params));
    }
    else if (Array.isArray(subRegion.subRegion) && subRegion.subRegion.length === 0) {
      setActiveSubRegion(null);
      const params = {
        subRegion: null,
        subRegionList: []
      };
      sessionStorage.setItem('subRegion', null);
      dispatch(updateBusinessUnitSubRegion(params));
    }
    const { state } = history.location;
    let parentKey;
    let childKey;
    if (!!ShareLinkData && Array.isArray(ShareLinkData['data']) && ShareLinkData['data'].length > 0) {
      parentKey = ShareLinkData['data'][0]['pageName'];
      childKey = ShareLinkData['data'][0]['pageType'];
      dispatch({ type: "FETCH_SHAREABLE_PLAYBOOKS_LINK_REQUEST" });
    }
    else if (!!state && state.hasOwnProperty('businessUnitData')) {
      parentKey = state['pageName'];
      childKey = state['pageType'];
    }
    console.log('handleRegionChangePath', parentKey, childKey, nextPathData,)
    if (parentKey && nextPathData) {
      let parentModule = nextPathData['finalBusinessUnit'].filter((item) => item.key === parentKey)[0];
      let activeParentModule = parentModule ? parentModule : nextPathData['finalBusinessUnit'][0];
      Array.isArray(activeParentModule.childModule) && activeParentModule.childModule.length > 0 &&
        activeParentModule.childModule.sort((a, b) => { return a.childOrder - b.childOrder });
      let activeChildModule = activeParentModule.childModule.find((el) => el.key === childKey);
      console.log('handleRegionChangePath', parentKey, childKey, nextPathData, state, activeParentModule, activeChildModule)
      if (activeChildModule) {
        sessionStorage.setItem('ActivePage', JSON.stringify(activeChildModule));
        dispatch(updateActivePage(activeChildModule))
        const params = {
          pageType: activeChildModule['key'],
          pageName: activeChildModule['parentModule'],
          sideBar: activeChildModule['sideBarKey'],
          businessUnitData: activeParentModule.childModule,
          region: value, //pathName === activeRoutes.key4 ? "Global" :
          subRegion: Array.isArray(subRegion.subRegion) && subRegion.subRegion.length > 0 ? subRegion.subRegion[0] : null,
        }
        history.push({
          pathname: activeChildModule['path'],
          state: { ...params }
        });

      }
    }
    else if (!nextPathData) {
      let activeScreenPath = pathName !== '/' ? pathName.split('/') : null;
      let length = Array.isArray(activeScreenPath) && activeScreenPath.length;
      let parentPath = length === 3 ? activeScreenPath[length - 2] : null;
      console.log('handleRegionChangePath', parentKey, childKey, nextPathData, activeScreenPath)
      history.push({
        pathname: parentPath,
        state: { ...state }
      });
    }
  };

  /**
     * function for updating sub region selected by the user.
     *
     * @example
     * @state
     * subRegion = Active Selected Sub Region
     * resetPlaybooksRegionFilters = Reset Playbook Gloal filter on the basis of selected region
     * updateRegionFilters = Reset Regions filter on the basis of selected region
     * updateBusinessUnitSubRegion =  Update Active BusinessUnit Sub Region
 */
  const handleSubRegionChange = (event) => {
    let value = event.target.value;
    const index = regions.findIndex(item => item.region === activeRegion);
    let subRegion = regions && regions[index];
    const params = {
      subRegion: value,
      subRegionList: subRegion && subRegion.subRegion
    }
    setActiveSubRegion(value);
    sessionStorage.setItem('subRegion', value);
    dispatch(updateBusinessUnitSubRegion(params));
    dispatch(updateRegionFilters({}));
    dispatch(resetPlaybooksRegionFilters({}))
    dispatch(updatePresistReport({ presist: true }));
    dispatch({ type: "UPDATE_SIDEBAR_MENU_LIST" });
    localStorage.removeItem('reportFilter');
    sessionStorage.removeItem('presistReport');
  };

  /**
     * function to navigate home page.
     *
     * @example
     * @state
     * path =  Selected Screen Name
     * userTrackingInfo = Redirected from Home to Selected Screen
 */
  const Navigation = (path, screen) => {
    dispatch(updatesidebarMenuList([]));
    dispatch({ type: "GET_PLAYBOOKS_CONTENT_UPDATE" });
    dispatch(updateCustomizedViewBuilderJSON({}));
    dispatch(updateBookmarkViews({}));
    setCloseAnchorEl(true);
    sessionStorage.setItem('presistReport', null);
    history.push({
      pathname: path,
      state: { region: selRegion, source: 'homePage' }
    });
    if (screen !== "Home") {
      utils.userTrackingInfo({
        region: activeRegion === "loading..." ? 'APAC' : activeRegion,
        actionType: 'Click',
        pageName: 'Other Feature',
        pageDetails: `Redirected from Home to ${screen}`,
      });
    }
  };

  const NavigationEdit = (path) => {
    history.push({
      pathname: path,
      state: { region: selRegion, source: 'homePage' }
    });
  };


  const circularBackDrop = () => {
    return (
      apiFailure.message !== "Network Error" &&
        apiFailure.message !== 'Request failed with status code 404' &&
        // regionlist.userMessage !== "Some error occured while fetching the region and subregions. Please try again in some time." &&
        pathName === '/' && regions && regions.length === 0 ?
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="primary" />
          <div className={classes.wait}>Please Wait...</div>
        </Backdrop>
        : null
    )
  };

  const EmptyScreen = () => {
    return (
      <AppBar position="static" color="inherit" className={classes.appBar}>
        <Toolbar className={classes.root} variant="dense">
          <IconButton className={classes.logoButton} color="default" aria-label="logo" onClick={() => {
            dispatch(updateFirstTimeAppLoadStatus(false))
            Navigation('/', 'Home')
          }} none>
            <img src={logo} alt="logo" className={classes.logo} width="60px" />
            <div className={classes.vertical} />
          </IconButton>
          <IconButton className={classes.HomeButton} color="default" aria-label="logo" onClick={() => {
            dispatch(updateFirstTimeAppLoadStatus(false))
            Navigation('/', 'Home')
          }} none>
            <HomeIcon className={classes.logo} color={'primary'} fontSize={'large'} />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  };

  const renderEnvLabel = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "Dev" || process.env.REACT_APP_ENVIRONMENT === "QA") {
      return (
        <Typography variant="h6" className={classes.label}>
          <div className={classes.envlabel}>
            {process.env.REACT_APP_ENVIRONMENT}
          </div>
        </Typography>
      )
    }
    else if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
      return (
        <Typography variant="h6" className={classes.label}>
          <div className={classes.envlabel}>
            {process.env.REACT_APP_LABEL_CHECK}
          </div>
        </Typography>
      )
    }
  };

  const renderlogo = () => {
    return (
      <React.Fragment>
        {renderEnvLabel()}
        <IconButton className={classes.HomeButton} color="default" aria-label="logo" onClick={() => Navigation('/', 'Home')} none>
          <img src={home} alt="logo" className={classes.HomeIcon} />
        </IconButton>
        <IconButton className={classes.logoButton} color="default" aria-label="logo" onClick={() => Navigation('/', 'Home')} none>
          <img src={logo} alt="logo" className={classes.logo} width="38px" />
        </IconButton>
      </React.Fragment>
    )
  };

  const renderRegion = () => {
    if (pathName !== activeRoutes.key8 || pathName !== activeRoutes.key10 || pathName !== activeRoutes.key12) {
      return (
        <Box sx={{ minWidth: 100 }} className={classes.regionBox} style={{ width: '120px', padding: '5px 0 5px 12px' }}>
          <FormControl fullWidth>
            <NativeSelect
              value={selRegion && selRegion.region}
              inputProps={{
                name: 'region',
                id: 'uncontrolled-native',
              }}
              onChange={handleRegionChange}
              className={pathName === '/' || pathName === '/admin' ? classes.regionName : null}
              IconComponent={(_props) => {
                const rotate = _props.className.toString().includes("iconOpen");
                return (
                  <div
                    style={{
                      transform: rotate ? "rotate(180deg)" : "none",
                    }}
                    className="myImage"
                  >
                  </div>
                );
              }}
            >
              {
                regions && regions.length > 0 ? regions.map((item, index) =>
                  <option value={item.region} key={index}  >
                    {item.region}
                  </option>
                )
                  :
                  <option value={'...loading'}>
                    Loading...
                  </option>
              }
            </NativeSelect>
          </FormControl >
        </Box>
      )
    }
  };

  const renderSubRegion = () => {
    if (pathName === activeRoutes.key1 && selRegion && selRegion.hasOwnProperty("subRegion") && selRegion.subRegion.length > 0 && activeReport && activeReport.activeReportPage) {
      return (
        <Box sx={{ minWidth: 100, margin: '0 0 0 25px' }} className={classes.subRegionBox} style={{ width: '120px ', padding: '5px 0 5px 12px' }}>
          <FormControl fullWidth>
            <NativeSelect
              value={activeSubRegion}
              inputProps={{
                name: 'sub-region',
                id: 'uncontrolled-native',
              }}
              onChange={handleSubRegionChange}
              IconComponent={(_props) => {
                const rotate = _props.className.toString().includes("iconOpen");
                return (
                  <div
                    style={{
                      transform: rotate ? "rotate(180deg)" : "none",
                    }}
                    className="myImage"
                  >
                  </div>
                );
              }}
              className={pathName === '/' || pathName === '/admin' ? classes.regionName : null}
            >
              {
                selRegion.subRegion.map((subRegion, index) => (
                  <option value={subRegion} key={index} className={classes.regionName}>
                    {subRegion}
                  </option>
                ))
              }
            </NativeSelect>
          </FormControl>
        </Box>
      )
    }

  };

  const renderFeedback = () => {
    if (activeRegionSpecificConfigs && activeRegionSpecificConfigs['showFeedback']) {
      return (
        <div className={classes.supportFeedback}>
          <div id="feedback">
            <Feedback
              region={activeRegion}
              props={props}
            />
          </div>
          <img src={verticaLine} alt="line" className={classes.supportFeedbackAlign} />
        </div>
      )
    }
  };

  const renderBookmark = () => {
    let pathIndex = disabledFeatures && Array.isArray(disabledFeatures) && disabledFeatures.length > 0 && disabledFeatures.findIndex((el) => el.path === pathName)
    return (
      <div className={classes.flexContainer}>
        {
          pathIndex > -1 ?
            <div className={classes.flexContainer}>
              <Export
                subRegion={null}
              />
              <Share
                region={activeRegion}
                subRegion={null}
              />
              <img src={exportShare} alt="exportShare" className={classes.supportAlign} />
              <img src={verticaLine} alt="line" className={classes.supportFeedbackAlign} />
            </div>
            : null
        }
        <Bookmark
          activeRegion={activeRegion}
          activeSubRegion={activeSubRegion}
          regions={regions}
          props={props}
          updateRegion={updateBookmarkRegion}
        />
      </div>
    )
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setNoficationCenter({ ...notificationCenter, [anchor]: open });
    if (pathName !== activeRoutes.key13 && pathName !== activeRoutes.key14 && !!open) {
      let trackingObjects = {
        region: activeRegion,
        actionType: 'Click',
        pageName: 'Other Feature',
        reportName: 'Notification Center',
      }
      if (pathName === "/") {
        trackingObjects.pageDetails = `Redirected from Home to Notification Center`;
        utils.userTrackingInfo(trackingObjects);
      }
      else if (pathName !== "/") {
        let path = pathName.split('/')[1];
        trackingObjects.pageDetails = `Redirected from ${path} to Notification Center`;
        utils.userTrackingInfo(trackingObjects);
      }
    }
  };

  const renderNotification = () => {
    return (
      <div style={{ paddingLeft: "12px", paddingRight: "12px" }}>
        <Badge overlap="rectangular" color="secondary" badgeContent={notifications} style={{ cursor: 'pointer' }} onClick={toggleDrawer('right', true)} >
          <img src={objectsNotifications} alt="notification" width={'24px'} height={'24px'} />
        </Badge>
        <Drawer
          anchor={'right'}
          open={notificationCenter['right']}
          onClose={toggleDrawer('right', false)}
        >
          <NotificationCenter
            totalNotifications={notifications}
            activeRegion={activeRegion}
            regions={regions}
            props={props}
            email={user.email}
            closeToggleDrawer={toggleDrawer('right', false)}
          />
        </Drawer>
      </div>
    )
  };

  const updateMessageModal = () => {
    setUpdateMessage(true);
  }

  const updateErrorMessage = () => {
    return (
      <Modal
        open={updateMessage}
        bodyElement={
          <UpdateMessage
            hideSettingsWindow={(e) => setUpdateMessage(false)}
            region={activeRegion}
          />
        }
        title={"Update Error Messages"}
        size={"md"}
        hideSettingsWindow={(e) => setUpdateMessage(false)}
      />
    )
  };

  const renderMenuItem = () => {
    return (
      <div>
        <MenuItem onClick={() => Navigation('/user-profile', 'user-profile')}>Profile</MenuItem>
        {
          admin === "Contributor" &&
          <MenuItem onClick={() => Navigation(activeRoutes.key7, 'Admin Dashboard')}>Dashboard</MenuItem>
        }
        {
          admin === "Contributor" &&
          <MenuItem onClick={() => NavigationEdit('/landingPage/edit-home-screen')}>Manage Landing Page</MenuItem>
        }
        {
          admin === "Contributor" &&
          <MenuItem onClick={() => updateMessageModal()}>Update Message</MenuItem>
        }
        <MenuItem onClick={() => Navigation(activeRoutes.key11, 'Manage Alert')}>Manage Alerts</MenuItem>
        <MenuItem onClick={() => Navigation(activeRoutes.key13, 'Release Notes')}>Release Notes</MenuItem>
        <MenuItem onClick={() => Navigation(activeRoutes.key14, 'Newsletter')}>Newsletter</MenuItem>
      </div>
    )
  };


  const renderProfile = () => {
    return (
      <IconMenuItem
        ariaLabel={'account of current user'}
        TypoLabel={user ? user.name : userInfo && userInfo.name}
        fontSize={'1rem'}
        menuId={'menu-appbar'}
        menuItem={renderMenuItem()}
        prefixIcon={true}
        closeAnchorEl={closeAnchorEl}
        disabled={false}
        renderIcon={<img src={profileAvatar} alt="profile" width={40} height={40} />}
      />
    )
  };


  return (
    <div className={classes.root} id="header">
      {circularBackDrop()}
      {(apiFailure.message === "Network Error" || apiFailure.message === 'Request failed with status code 404') ?
        EmptyScreen()
        :
        <AppBar position="static" color="inherit" className={classes.appBar}>
          <Toolbar className={classes.root} variant="dense" disableGutters={true}>
            {renderlogo()}
            {

              renderRegion()
            }
            {
              renderSubRegion()
            }
            <Typography variant="h6" className={classes.flexgrow} />
            {renderFeedback()}
            {renderBookmark()}
            {updateErrorMessage()}
            {renderProfile()}
            {renderNotification()}
          </Toolbar>
        </AppBar>
      }
    </div>
  );
}

export default Header;
